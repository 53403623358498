import React from 'react';

const Slides = () => {
    const style = { position: 'absolute', top: '60%', left: '40%', 'zIndex': 1000 };
    return (
        <React.Fragment>
            <div id="home" className="slider-area">
                <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
                    <ol class="carousel-indicators">
                        <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
                        <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                        <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
                        <li data-target="#carouselExampleIndicators" data-slide-to="3"></li>
                        <li data-target="#carouselExampleIndicators" data-slide-to="4"></li>
                    </ol>
                    
                    <div class="carousel-inner">
                        <div className="carousel-item active">
                            <img className="d-block w-100" src="assets/img/slider/slider1.jpg" alt="First slide"/>
                            <div class="carousel-caption d-none d-md-block">
                                <div className="layer-1-3 hidden-xs wow animate__slideInUp animate__animated" data-wow-duration="2s" data-wow-delay=".2s">
                                    <a className="ready-btn right-btn page-scroll" href="#services">See Services</a>
                                    <a className="ready-btn page-scroll" href="#about">Learn More</a>
                                </div>
                            </div>
                        </div>
                        <div className="carousel-item">
                            <img className="d-block w-100" src="assets/img/slider/slider2.jpg"  alt="Second slide"/>
                            <div class="carousel-caption d-none d-md-block">
                                <div className="layer-1-3 hidden-xs wow animate__slideInUp animate__animated" data-wow-duration="2s" data-wow-delay=".2s">
                                    <a className="ready-btn right-btn page-scroll" href="#services">See Services</a>
                                    <a className="ready-btn page-scroll" href="#about">Learn More</a>
                                </div>
                            </div>
                        </div>
                        <div className="carousel-item">
                            <img className="d-block w-100" src="assets/img/slider/slider3.jpg" alt="Third slide"/>
                            <div class="carousel-caption d-none d-md-block">
                                <div className="layer-1-3 hidden-xs wow animate__slideInUp animate__animated" data-wow-duration="2s" data-wow-delay=".2s">
                                    <a className="ready-btn right-btn page-scroll" href="#services">See Services</a>
                                    <a className="ready-btn page-scroll" href="#about">Learn More</a>
                                </div>
                            </div>
                        </div>
                        <div className="carousel-item">
                            <img className="d-block w-100" src="assets/img/slider/slider4.jpg" alt="Fourth slide"/>
                            <div class="carousel-caption d-none d-md-block">
                                <div className="layer-1-3 hidden-xs wow animate__slideInUp animate__animated" data-wow-duration="2s" data-wow-delay=".2s">
                                    <a className="ready-btn right-btn page-scroll" href="#services">See Services</a>
                                    <a className="ready-btn page-scroll" href="#about">Learn More</a>
                                </div>
                            </div>
                        </div>
                        <div className="carousel-item">
                            <img className="d-block w-100" src="assets/img/slider/slider5.jpg" alt="Fifth slide"/>
                            <div class="carousel-caption d-none d-md-block">
                                <div className="layer-1-3 hidden-xs wow animate__slideInUp animate__animated" data-wow-duration="2s" data-wow-delay=".2s">
                                    <a className="ready-btn right-btn page-scroll" href="#services">See Services</a>
                                    <a className="ready-btn page-scroll" href="#about">Learn More</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="sr-only">Previous</span>
                    </a>
                    <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="sr-only">Next</span>
                    </a>
                </div>
            </div>
        </React.Fragment >
    )
};

export default Slides;
