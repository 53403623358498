import React from 'react';

const Contact = () => (
    <React.Fragment>
        <div id="contact" className="contact-area">
            <div className="contact-inner area-padding">
                <div className="contact-overly"></div>
                <div className="container ">
                    <div className="row">
                        <div className="col-md-12 col-sm-12 col-xs-12">
                            <div className="section-headline text-center">
                                <h2>Contact us</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">

                        <div className="col-md-4 col-sm-4 col-xs-12">
                            <div className="contact-icon text-center">
                                <div className="single-icon">
                                    <i className="fa fa-mobile"></i>
                                    <p>
                                        Call: +91 9821521694/9987281125<br />
                                        <span>Monday-Saturday (10am-1pm/6pm-9pm)</span>
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4 col-sm-4 col-xs-12">
                            <div className="contact-icon text-center">
                                <div className="single-icon">
                                    <i className="fa fa-envelope-o"></i>
                                    <p>
                                        Email: patilbbhagyashree@gmail.com<br />
                                        <span>Web: www.example.com</span>
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4 col-sm-4 col-xs-12">
                            <div className="contact-icon text-center">
                                <div className="single-icon">
                                    <i className="fa fa-map-marker"></i>
                                    <p>
                                    Shatayu Ayurved and Panchkarma Clinic<br />
                                    Shop no 5, Anant Apartment, Mhatrewadi, <br />
                                    Opp LandMark: Bank of Barida,, Bharucha Rd, <br />
                                    Dahisar West, <br />
                                        <span>Mumbai, Maharashtra 400068</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">


                        <div className="col-md-12 col-sm-12 col-xs-12">

                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1478.3414808387863!2d72.85659910693357!3d19.250408063793994!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x42f9adf0da4a903!2sShatayu%20Ayurved%20and%20Panchkarma%20Clinic!5e0!3m2!1sen!2sin!4v1611491754078!5m2!1sen!2sin" width="100%" height="380" frameBorder="0" style={{ "border": 0 }} allowFullScreen></iframe>

                        </div>

                    </div>
                </div>
            </div>
        </div>
    </React.Fragment>
);

export default Contact;
