import React from 'react';

const Header = () => (
  <header id="header" className="fixed-top">
    <div className="container d-flex">

      <div className="logo mr-auto">
        <h1 className="text-light"><a href="index.html"><span>S</span>hatayu</a></h1>
      </div>

      <nav className="nav-menu d-none d-lg-block">
        <ul>
          <li className="active"><a href="#header">Home</a></li>
          <li><a href="#about">About</a></li>
          <li><a href="#services">Services</a></li>
          <li><a href="#team">Team</a></li>
          <li><a href="#testimonials">Testimonials</a></li>
          <li><a href="#contact">Contact</a></li>

        </ul>
      </nav>

    </div>
  </header>
);

export default Header;
