import React from 'react';

const ServicesTab = () => (
    <React.Fragment>
        <div id="services" className="services-area area-padding">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 col-sm-12 col-xs-12">
                        <div className="section-headline services-head text-center">
                            <h2>Our Services</h2>
                        </div>
                    </div>
                </div>
                <div className="row text-center">

                    <div className="col-md-4 col-sm-4 col-xs-12">
                        <div className="about-move">
                            <div className="services-details">
                                <div className="single-services">

                                    <h4>Naadi Parikshan</h4>
                                    <p>
                                        At Shatayu , we understand that patient at times may not be able to provide the right symptoms to doctor and this is where Naadi Parikshan comes into picture. Naadi Parikshan helps know the Doshas and helps providing authentice Ayurvedic treatment.
                  </p>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="col-md-4 col-sm-4 col-xs-12">
                        <div className="about-move">
                            <div className="services-details">
                                <div className="single-services">

                                    <h4>Treatment History and Progress tracking</h4>
                                    <p>
                                        At Shaatyu , we set high standards for professionlism, patients history and progress is stored securely and is analysed thorughly for treatment.
                  </p>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="col-md-4 col-sm-4 col-xs-12">

                        <div className=" about-move">
                            <div className="services-details">
                                <div className="single-services">

                                    <h4>Authentic Panchakarma Treatments</h4>
                                    <p>
                                        Panchakarma is a very powerful branch of Ayurveda which helps in fastening the speed of treatment. We perform authentic Pachakarma treatments
                  </p>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="col-md-4 col-sm-4 col-xs-12">

                        <div className=" about-move">
                            <div className="services-details">
                                <div className="single-services">

                                    <h4>Counselling(Treatment related) </h4>
                                    <p>
                                        At Shatayu we make sure to hear out every detail the patient provides and leave no stone unturned to educate patient not only treatments but on healthy liefstyle changes.
                  </p>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="col-md-4 col-sm-4 col-xs-12">

                        <div className=" about-move">
                            <div className="services-details">
                                <div className="single-services">

                                    <h4>Diet Recommendations</h4>
                                    <p>
                                        As per Ayurveda our lifestyle and food habits define our health. Shatayu fully understands this and the diet is different for every individual based on prakarti.
                  </p>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="col-md-4 col-sm-4 col-xs-12">

                        <div className=" about-move">
                            <div className="services-details">
                                <div className="single-services">

                                    <h4>Support</h4>
                                    <p>
                                        Shatayu can proudly talk about the support provided and is one of our strong points. You can be sure that once you are Shatayu family, you will always be Shatayu fmmily.
                  </p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </React.Fragment>
);

export default ServicesTab;
