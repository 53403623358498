import React from 'react';

const Team = () => (
    <React.Fragment>
        <div id="team" className="our-team-area area-padding">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 col-sm-12 col-xs-12">
                        <div className="section-headline text-center">
                            <h2>Our Team of Doctors</h2>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12 col-lg-12 col-lg-12" style={{"textAlign":"center"}}>
                        <div className="single-team-member">
                            <div className="team-img">
                                <a href="#">
                                    <img src="assets/img/team/4.jpg" alt="" />
                                </a>
                                <div className="team-social-icon text-center">
                                    <ul>
                                        <li>
                                            <a href="https://www.facebook.com/Shatayurved" target="_blank">
                                                <i className="fa fa-facebook"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.instagram.com/vaidyabhagyashreebhagyashree" target="_blank">
                                                <i className="fa fa-instagram"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="team-content text-center">
                                <h4>Dr Bhagyashree Patil (B.A.M.S)</h4>
                                <p>Doctor</p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </React.Fragment>
);

export default Team;
