import React from 'react';

const Testimonials = () => (
    <React.Fragment>
        <div id="testimonials" className="testimonials-area">
            <div className="testi-inner area-padding">
                <div className="testi-overly"></div>
                <div className="container ">
                    <div className="row">
                        <div className="col-md-12 col-sm-12 col-xs-12">

                            <div className="testimonial-content text-center">
                                <a className="quate" href="#"><i className="fa fa-quote-right"></i></a>

                                <div className="owl-carousel testimonial-carousel">
                                <div className="single-testi">
                                        <div className="testi-text">
                                            <p>
                                            Excellent experience for close to a decade now with Shatau Clinic and Dr. Bhagyashree. She is a great doctor (वैद्य) and a kind hearted human being - a rare combo. Have benefitted in both acute and chronic illnesses for self and entire family.
                      </p>
                                            <h6>Vinayak Gokhale</h6>
                                        </div>
                                    </div>
                                    <div className="single-testi">
                                        <div className="testi-text">
                                            <p>
                                                The doctor is very genuine and does not recommend unwanted tests I got cured from severe IBS I also take panchkarma every year because of which my use of asthma pump has gone away completely.
                      </p>
                                            <h6>Adit Shetty</h6>
                                        </div>
                                    </div>
                                    <div className="single-testi">
                                        <div className="testi-text">
                                            <p>
                                                Had a nice experience. Doctor is Intelligent, professional, calm and polite. Will recommend her to everyone..
                      </p>
                                            <h6>Samidha Kapadi</h6>
                                        </div>
                                    </div>

                                    <div className="single-testi">
                                        <div className="testi-text">
                                            <p>
                                                Experience was good and doctor is familiar and treatment was good.
                      </p>
                                            <h6>Deepak Salvi</h6>
                                        </div>
                                    </div>

                                    <div className="single-testi">
                                        <div className="testi-text">
                                            <p>
                                                I had chronic problem of acidity and treatment with Dr Patil I am now acidity fee.Thank Dr Patil
                      </p>
                                            <h6>Ashwini Raut</h6>
                                        </div>
                                    </div>

                                    <div className="single-testi">
                                        <div className="testi-text">
                                            <p>
                                                One of best docotor
                      </p>
                                            <h6>Khushi Shah</h6>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </div>
    </React.Fragment>
);

export default Testimonials;
