import React from 'react';

const About = () => (
    <React.Fragment>
        <div id="about" className="about-area area-padding">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 col-sm-12 col-xs-12">
                        <div className="section-headline text-center">
                            <h2>About Shatayu</h2>
                        </div>
                    </div>
                </div>
                <div className="row">

                    <div className="col-md-6 col-sm-6 col-xs-12">
                        <div className="well-left">
                            <div className="single-well">
                                <a href="#">
                                    <img src="assets/img/about/1.jpg" alt="" style={{"width":"80%"}}></img>
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6 col-sm-6 col-xs-12">
                        <div className="well-middle">
                            <div className="single-well">
                                <a href="#">
                                    <h4 className="sec-head">Brief</h4>
                                </a>
                                <p>
                                    Shatayu is an Ayurvedic and Panchakarma clinic, Shatayu was founded in 2007 by Dr Bhagyashree Patil<br/>
                                    At Shatayu we practice pure ayurveda. Every patient's history and progress is tracked and patient is made well aware with the treatments and its effects.
                                    Shatayu has a reputation of transparency, support and mentoring patients.


                </p>
                                <p>Treatments For</p>
                                <ul>
                                    <li>
                                        <i className="fa fa-check"></i> Diabetes
                  </li>
                                    <li>
                                        <i className="fa fa-check"></i> Arthritis
                  </li>
                                    <li>
                                        <i className="fa fa-check"></i> Migranes
                  </li>
                                    <li>
                                        <i className="fa fa-check"></i> Obesity
                  </li>
                                    <li>
                                        <i className="fa fa-check"></i> Indigestion and sever acidity
                  </li>
                                    <li>
                                        <i className="fa fa-check"></i> Respiratory related diseases
                  </li>
                                    <li>
                                        <i className="fa fa-check"></i> Hair Fall
                  </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </React.Fragment>
);

export default About;
