import React from 'react';

const Footer = () => (
  <footer>
    <div className="footer-area">
      <div className="container">
        <div className="row">
          <div className="col-md-4 col-sm-4 col-xs-12">
            <div className="footer-content">
              <div className="footer-head">
                <div className="footer-logo">
                  <h2><span>S</span>hatayu</h2>
                </div>

                <p>Please feel free to contact via mobile or email. Questions are always welcome at Shatayu</p>
                
              </div>
            </div>
          </div>

          <div className="col-md-4 col-sm-4 col-xs-12">
            <div className="footer-content">
              <div className="footer-head">
                <h4>information</h4>
                
                <div className="footer-contacts">
                  <p><span>Tel:</span> +91 9987281125/9821521694</p>
                  <p><span>Email:</span> patilbbhagyashree@gmail.com</p>
                  <p><span>Working Hours:</span> (Mon-Sat) 9:30 am - 1:00 pm/ 6:00 pm-9:00 pm</p>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-4 col-sm-4 col-xs-12">
            <div className="footer-content">
              <div className="footer-head">
                <h4>Gallery</h4>
                <div className="flicker-img" style = {{"display":'none'}}>
                  <a href="#"><img src="assets/img/portfolio/1.jpg" alt="" /></a>
                  <a href="#"><img src="assets/img/portfolio/2.jpg" alt="" /></a>
                  <a href="#"><img src="assets/img/portfolio/3.jpg" alt="" /></a>
                  <a href="#"><img src="assets/img/portfolio/4.jpg" alt="" /></a>
                  <a href="#"><img src="assets/img/portfolio/5.jpg" alt="" /></a>
                  <a href="#"><img src="assets/img/portfolio/6.jpg" alt="" /></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="footer-area-bottom">
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-sm-12 col-xs-12">
            <div className="copyright text-center">
              <p>
                &copy; Copyright <strong>Shatayu and Ayurveda Pachakarma</strong>. All Rights Reserved
              </p>
            </div>
            
          </div>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
