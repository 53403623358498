import React from 'react';
import Slides from './slides/slides';
import About from './about/about';
import ServicesTab from './servicestab/servicestab';
import Team from './team/team';
import Testimonials from './testimonials/testimonials';
import Contact from './contact/contact';


const Main = () => (
  <React.Fragment>
    <Slides></Slides>
    <main id="main">
      <About></About>
      <ServicesTab></ServicesTab>
      <Team></Team>
      <Testimonials></Testimonials>
      <Contact></Contact>
    </main>
  </React.Fragment>
);

export default Main;
